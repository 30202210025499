
// Default .button class
.button {
    @extend %button;
    background: $color-primary;
    display: inline-block;
    padding: 0 $block-padding;
    line-height: 45px;
    line-height: 45px;

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}

.read-more {
    @extend .button;
    background: $color-secondary;
    @extend .uppercase;
    color: $color-white;
    border: 1px solid $color-secondary-light;

    &:hover {
        background: $color-secondary-light;
    }
}
