
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.uppercase {
    font-size: $font-size-body - 0.3;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.3;
}

//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    border: none;
    color: $color-white;
    font-weight: $font-bold;
    text-align: center;
    transition: $transition;
}

%hover {
    background: $color-quarternary;
    color: $color-white;
}

// extend for default .read-more
%read-more {
    @extend .button;
}

