
// Content styles
.block-text,
.biography,
.column-centre-blocks {
	line-height: 1.7;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding: ($paragraph-margin * 2) 0 $paragraph-margin;
			border-top: $border;
			// border-bottom: $border;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h4;
			
			@media #{$mobileXL} {
				font-size: $h3;
			}
		}

		&:last-of-type {
			padding: 0 0 ($paragraph-margin * 2);
			border-bottom: $border;
		}
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
	}
	
}

