// related items list
.blog-advert-container {

	.related-items {
		@extend %advert-block !optional;
		border-top: $border-size $border-style $color-lightgrey;
		background: $color-white;

		> .title {
			// @extend %sub-title;
		}
	}
}
.section-library {
	.main {
		.centre {
			.related-items {
				border: none;
				.items {
					.item {
						border-bottom: $border;
						padding-bottom: $site-padding + 0.5rem;
						margin-bottom: $block-margin - 1rem;

						&:last-child {
							border: none;
						}

						.related-title {
							display: block;
							font-size: $font-size-body + 0.3rem;
						}
					}
				}
			}
		}
	}
}
.section-blog {
	.main {
		.left {
			.news-items {
				text-align: center;
				border: none;
				padding: $block-padding;

				.items {
					.item {
						border-bottom: $border;
						padding-bottom: $site-padding + 0.5rem;
						margin-bottom: $block-margin - 1rem;

						&:last-child {
							border: none;
						}

						.related-title {
							display: block;
							font-size: $font-size-body + 0.3rem;
						}
					}
				}
			}
		}
	}
}
